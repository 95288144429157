import { addQueriesParam, StringMap } from '@canalplus/mycanal-commons';
import type { AccountUrl } from '../../../constants/url';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { offerLocationSelector } from '../../../store/slices/application-selectors';
import { getPublicConfig } from '../../config/config-helper';
import { isIntegration } from '../../url/url-helper';

/**
 * Returns a proper Account path url.
 * Concatenates Account `base` url (REC or not) with `offerLocation`, accountUrl `path` and adds missing `?queries`
 *
 * @param accountUrl    Account /path/ that will be concatenated with the base account URL/{offerLocation}/
 * @param queries       The queries to be added, ex: [['query1', value], ['query2', value], ...]
 * @returns Returns account url
 *
 * @example
 *  useGetAccountPathUrl('moncompte/code/parental', [['redirectCodeSuccess', 'https://www.canalplus.com']])
 *  // => 'http://account.canalplus.com/fr/moncompte/code/parental/?redirectCodeSuccess=https://www.canalplus.com'
 */
export const useGetAccountPathUrl = (
  accountUrl: AccountUrl,
  queries?: StringMap
): string => {
  const offerLocation = useInvariantSelector(offerLocationSelector);
  const { base_url: rawBaseUrl, integration_base_url: integrationBaseUrl } =
    getPublicConfig().app.account;

  const baseUrl = isIntegration() ? integrationBaseUrl : rawBaseUrl;
  const url = `${baseUrl}/${offerLocation}/${accountUrl}`;

  return queries ? addQueriesParam(url, queries) : url;
};
