import { getUrlWithProtocol } from './getUrlWithProtocol';

/**
 * URL Helper
 * --------------------
 *
 * Add a **protocol** to an url and encodes it.
 *
 * @param url The **url** to parse
 * @returns Returns encoded url with the protocol or an empty string if url is empty
 *
 * @category Url
 * @examples
 * getEncodedUrlWithProtocol('url.com')
 * // => 'https%3A%2F%2Furl.com'
 * getEncodedUrlWithProtocol('')
 * // => ''
 *
 */
export const getEncodedUrlWithProtocol = (url: string): string => {
  if (!url) {
    return '';
  }
  const urlWithProtocol = getUrlWithProtocol(url);
  return encodeURIComponent(urlWithProtocol);
};
