import { AcmSdkConfig } from '@canalplus/sdk-acm';
import { useContext } from 'react';
import { AcmSdkContext } from '../context';

/**
 * Hook to get the ACM SDK context (`AcmSdkConfig`)\
 * Throws an error if the context is not available
 */
export const useAcmSdkContext = (): AcmSdkConfig => {
  const config = useContext(AcmSdkContext);

  if (config === null) {
    throw new Error('AcmSdkContext is not defined');
  }

  return config;
};
