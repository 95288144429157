import { fetch } from '@canalplus/mycanal-fetch';
import { LogApiMetadata, Service } from '@canalplus/mycanal-logger';
import { AcmErrorCode, CheckSafetyCodeResponse } from '@canalplus/types-acm';
import { AcmSdkConfig } from '../config/types';
import { AcmApi } from '../constants/paths';
import { AcmError } from '../error/AcmError';
import { AcmLockedSafetyCodeError } from '../error/AcmLockedSafetyCodeError';
import { getFilledApiUrl } from '../helpers/getFilledApiUrl';
import { isAcmApiError } from '../helpers/isAcmApiError';
import { logError } from '../helpers/logError';

/** @category Function parameters */
export type CheckPurchaseCodeParameters = AcmSdkConfig & {
  passToken: string;
  purchaseCode: string;
  contextId?: string;
};

/**
 * Fetch **checkPurchaseCode** ACM Api
 *
 * - `contextId` is optional and will be used to generate the `validationToken` if provided
 * - `validationToken` from the response is only defined if the `purchaseCode` is checked
 */
export async function checkPurchaseCode({
  appKeyLog,
  contextId,
  endpoint,
  fetchOptions,
  logger,
  logMetadata,
  offerLocation,
  offerZone,
  passToken,
  purchaseCode,
  requestId,
}: CheckPurchaseCodeParameters): Promise<CheckSafetyCodeResponse> {
  const startAt = Date.now();
  const api: AcmApi = 'checkPurchaseCode';

  const url = getFilledApiUrl({
    api,
    endpoint,
    offerLocation,
    offerZone,
  });

  const baseErrorLogs = {
    ...logMetadata,
    endpoint: url,
    offerLocation,
    offerZone,
    route: api,
    startAt,
    req: { appData: { appKey: appKeyLog }, ...(requestId && { requestId }) },
  } satisfies LogApiMetadata;

  try {
    const checkPurchaseCodeResponse = await fetch(url, {
      ...fetchOptions,
      headers: {
        ...fetchOptions?.headers,
        passToken,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ purchaseCode, ...(contextId && { contextId }) }),
    });

    logger.info(
      `ACM ${api} ${checkPurchaseCodeResponse.status}`,
      logger.generateApiMetadata(Service.Acm, api, {
        ...baseErrorLogs,
        statusCode: checkPurchaseCodeResponse.status,
      })
    );

    const parsedCheckPurchaseCode = await checkPurchaseCodeResponse.json();

    if (checkPurchaseCodeResponse.ok) {
      if (checkPurchaseCodeResponse.status === 204) {
        throw new Error(
          `ACM ${api} ${checkPurchaseCodeResponse.status}, code does not exist`
        );
      }

      return parsedCheckPurchaseCode as CheckSafetyCodeResponse;
    }

    if (isAcmApiError(parsedCheckPurchaseCode)) {
      const { errorCode, errorDescription, statusCode } =
        parsedCheckPurchaseCode;

      if (errorCode === AcmErrorCode['Code_401.2']) {
        throw new AcmLockedSafetyCodeError();
      }

      throw new AcmError(errorCode, errorDescription, statusCode);
    }

    throw new Error(`ACM ${api} ${checkPurchaseCodeResponse.status}`);
  } catch (error) {
    logError({ error, logger, baseErrorLogs, api });

    throw error;
  }
}
